var render = function render(){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"py-4",staticStyle:{"background-color":"#fafafa"},attrs:{"colspan":_vm.headers.length}},[_c('v-data-table',{staticClass:"elevation-1 rounded-xl mx-3 pa-1",staticStyle:{"background-color":"#fafafa"},attrs:{"loading":_vm.loading,"headers":_vm.dataHeaders,"items":_vm.data,"items-per-page":100,"data-aos":"fade-up","data-aos-delay":"100","data-aos-duration":"800"},scopedSlots:_vm._u([{key:"item.index",fn:function({ index }){return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:"item.action",fn:function({ item }){return [_c('div',{staticClass:"d-flex flex-column flex-col"},[_c('v-btn',{attrs:{"variant":"warning","small":""},on:{"click":function($event){return _vm.$emit('edit', item)}}},[_c('v-icon',[_vm._v("mdi-pencil")]),_c('span',[_vm._v("Perbarui")])],1)],1)]}},{key:"item.status",fn:function({ item }){return [_c('div',{staticClass:"d-flex flex-column align-items-center min-w-150px"},[_c('span',{staticClass:"badge",class:{
                    'bg-warning': item.validation == 0,
                    'bg-success': item.validation == 1
                }},[_vm._v(_vm._s(item.validation == 0 ? 'Belum Diverifikasi' : 'Diverifikasi FC'))])])]}},{key:"item.adjustment_validation",fn:function({ item }){return [_c('div',{staticClass:"d-flex flex-column align-items-center"},[_c('span',{staticClass:"badge",class:{
                    'bg-warning': item.adjustment_validation == 0,
                    'bg-success': item.adjustment_validation == 1
                }},[_vm._v(_vm._s(item.adjustment_validation == 0 ? 'Belum Diverifikasi' : 'Diverifikasi FC'))])])]}},{key:"item.land_area",fn:function({ item }){return [(item.lahan_datas)?_c('span',{staticClass:"font-weight-bold d-block min-w-150px"},[_vm._v(" "+_vm._s(item.lahan_datas.luas_lahan_gis ? item.lahan_datas.luas_lahan_gis : item.lahan_datas.luas_lahan)+" m2 ")]):_vm._e()]}},{key:"item.coordinate",fn:function({ item }){return [_vm._v(" "+_vm._s(item.lahan_datas?.koordinat_lahan)+" ")]}},{key:"item.opsi_pola_tanam",fn:function({ item }){return [_vm._v(" "+_vm._s(item.lahan_datas?.opsi_pola_tanam)+" ")]}},{key:"item.farmer_signature",fn:function({ item }){return [_c('div',{staticClass:"d-flex flex-row justify-content-center"},[_c('span',{staticClass:"badge",class:{
                    'bg-success': item.signature,
                    'bg-warning': !item.signature
                }},[_vm._v(_vm._s(item.signature ? 'Sudah' : 'Belum'))])])]}},{key:"item.attendance",fn:function({ item }){return [_c('div',{staticClass:"d-flex flex-row min-w-100px"},[_c('span',{staticClass:"badge",class:{
                    'bg-success': item.attendance,
                    'bg-danger': !item.attendance
                }},[_vm._v(_vm._s(item.attendance ? 'Hadir' : 'Tidak Hadir'))])])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }